{
  "common": {
    "menu_nothing":"Niets",
    "menu_min_message": "Het minimum aantal berichten is 1",
    "menu_field_cannot_be_empty_need_one": "Ten minste één veld moet worden ingevuld",
    "menu_affichage": "Melding op scherm",
    "menu_son": "Geluid",
    "menu_post_alert": "Post-alarm",
    "menu_vibration_5_secondes": "Trillingen elke 5 seconden",
    "menu_phone_number_must_start": "Het telefoonnummer mag alleen cijfers bevatten",
    "menu_wrong_file_certificate": "Verkeerd bestandsformaat. Het formaat moet .pfx of .p12 zijn.",
    "menu_wrong_config": "De configuratie is onjuist",
    "menu_see_help_for_more_info": "Raadpleeg de online hulp voor meer informatie.",
    "menu_back": "Terug",
    "menu_save": "Opslaan",
    "menu_settings": "Instellingen",
    "menu_notification_prealert": "Pre-alarmmelding",
    "menu_notification_by_vibration": "Trilmelding",
    "menu_notification_by_sound": "Geluidsmelding",
    "menu_notification_by_flash": "Flitsmelding",
    "menu_notification_duration": "Duur van de pre-alarmmelding",
    "menu_seconds": "seconden",
    "menu_minutes": "minuten",
    "menu_degrees": "graden",
    "menu_meters": "meter",
    "menu_settings_for": "Instellingen voor ",
    "menu_confirm": "Bevestigen ",
    "menu_field_cannot_be_empty": "Het veld mag niet leeg zijn",
    "menu_select_at_least_one_triggering_mode": "Selecteer ten minste één activeringsmodus",
    "menu_phone_number_must_start_with_+": ":”Het telefoonnummer moet beginnen met + en mag alleen cijfers bevatten”",
    "menu_phone_number_is_too_short": "Telefoonnummer te kort",
    "menu_phone_number_already_used": "Telefoonnummer al in gebruik",
    "menu_select_at_least_one_event": "Selecteer ten minste één gebeurtenis",
    "menu_are_you_sure": "Weet u het zeker?",
    "menu_add": "Toevoegen",
    "menu_modify": "Wijzigen",
    "menu_remove": "Verwijderen",
    "menu_remove_everything": "Alles verwijderen",
    "menu_wrong_flie_format": "Bestandsformaat onjuist. Sla het Excel-bestand op in .csv-formaat met een puntkomma als scheidingsteken.",
    "menu_cancel": "Annuleren"
  },
  "configuration": {
    "menu_no_checked_standard": "Er zijn geen parameters gemarkeerd voor de standaardmodus",
    "menu_no_checked_transparent": "Er zijn geen parameters gemarkeerd voor de transparante modus",
    "menu_location_standard": "Standaardmodus",
    "menu_location_transparent": "Transparante modus",
    "menu_location_indoor": "Binnen",
    "menu_type_action": "Type actie geassocieerd met drie snelle tikken",
    "menu_device_stop": "Apparaat stoppen",
    "menu_custom_on_off": "Aanpassen aan/uit-knop",
    "menu_trigger_alert":"Alarm activeren",
    "menu_trigger_call": "Activeer een oproep door snel driemaal op het volgende nummer te tikken:",
    "menu_tippy_text_on_off":"Ga naar [Aanpassing van de aan / uit-knop] om de functionaliteit in te schakelen",
    "menu_show_last_location": "Bewaar de laatst bekende locatie bij elke apparaatstop",
    "menu_force_alert_reactivated_system": "Forceer de heractivering van het systeem telkens wanneer het apparaat wordt ingeschakeld",
    "menu_err_serv_with_no_events": "Er zijn geen gebeurtenissen geconfigureerd",
    "menu_err_one_or_several_recipient_have_message_but_no_events": "Een of meer ontvangers ontvangen gebeurtenissen per sms, maar er zijn geen gebeurtenissen geconfigureerd",
    "menu_img_upload_failure": "De beeldoverdracht is mislukt, controleer uw internetverbinding",
    "menu_choose_at_least_one_custom_screen": "Er is geen configuratie uitgevoerd",
    "menu_custom_screens": "De weergave aanpassen",
    "menu_communication_option": "Communicatieopties",
    "menu_system_option": "Systeemopties",
    "menu_disable_on_off_btn": "Schakel de aan/uit-knop uit om te voorkomen dat het apparaat uitschakelt",
    "menu_model_check_emerit_version_unstopable_call": "Voor deze instelling moet de firmware van het apparaat compatibel zijn. U kunt de ROM-versie controleren via de widget Over.",
    "menu_tippy_unstopable_call_not_available": "Deze instelling is niet compatibel met de firmware van uw apparaat. Neem contact op met de technische dienst voor meer informatie.",
    "menu_call_cant_be_stop": "De alarmoproepen kunnen niet worden onderbroken door de gebruiker",
    "menu_whitelist": "Witte lijst (inkomende oproepen beperkt)",
    "menu_wifi_configuration": "Wifiverbinding",
    "menu_tippy_sip_not_available": "VoIP kan niet geactiveerd worden op uw apparaat",
    "menu_tippy_widget_loc": "Voor deze instelling moet  ten minste één van de locatiebepalingsmodi worden geactiveerd",
    "menu_model_check_emerit_version": "Voor VOIP moet de Emerit SIP-toepassing op dit apparaat aanwezig zijn. U kunt controleren of deze aanwezig is via de widget Over.",
    "menu_enabling_sip_will_remove_recipients": "Door VoIP te activeren worden geregistreerde interne ontvangers verwijderd. U moet een nieuwe ontvangerslijst invoeren.",
    "menu_disabling_sip_will_remove_recipients": "Door VoIP te deactiveren worden geregistreerde interne ontvangers verwijderd. U moet een nieuwe ontvangerslijst invoeren.",
    "menu_wrong_character_in_user_id": "De gebruikersnaam kan niet het '_' teken bevatten wanneer er ten minste één Emerit-horloge als interne ontvanger is geconfigureerd",
    "menu_choose_notif_mode": "Selectie van de meldingsmodus voor inkomende SMS-berichten",
    "menu_err_choose_at_least_one_notif_mode": "Selecteer ten minste één meldingsmodus",
    "menu_alerts_processing": "Verwerking van alarmmeldingen",
    "menu_internal_recipients": "Interne ontvangers",
    "menu_alarm_monitoring_center": "Alarmcentrale",
    "menu_alarm_monitoring_server": "Controleserver",
    "menu_alerts_localization": "Locatiebepaling van de alarmmeldingen",
    "menu_location_never": "Nooit",
    "menu_location_outdoor": "Buiten",
    "menu_alerts_triggering_modes": "Activeringsmodus van de alarmmeldingen",
    "menu_alert_activation_sos_button": "SOS-knop",
    "menu_alert_activation_sos_button_and_programmable_button": "SOS-knop en programmeerbare knoppen",
    "menu_alert_activation_side_black_button": "Zwarte knop zijkant",
    "menu_alert_activation_side_yellow_button": "Gele knop zijkant",
    "menu_alert_activation_front_programmable_button": "Programmeerbare knop voorkant",
    "menu_alert_activation_side_programmable_button": "Programmeerbare knop zijkant",
    "menu_alert_activation_automatic_detection": "Automatische detecties",
    "menu_wrist_alert_mode": "Snelle polsbewegingen",
    "menu_wearing_style_wrist": "Om de pols",
    "menu_wearing_style_waist": "Op de taille",
    "menu_wearing_style_arms": "Om de arm",
    "menu_wearing_style_neck": "Om de hals",
    "menu_alert_activation_power_button": "Aan/uit-knop",
    "menu_update_configuration": "Configuratie updaten",
    "menu_configuration_update_has_succeeded": "De configuratie-update is gelukt",
    "menu_device_must_be_restarted": "Het apparaat opstarten of de procedure starten voor het laden van de nieuwe configuratie",
    "menu_no_alert_recipient_was_selected": "Er zijn geen ontvangers voor de alarmmelding geselecteerd",
    "menu_no_alert_processing_was_selected": "Er is geen alarmverwerking geselecteerd",
    "menu_no_recipient_or_server_was_created": "Er zijn geen ontvangers of servers aangemaakt",
    "menu_no_recipient_was_created": "Er zijn geen ontvangers aangemaakt",
    "menu_no_server_was_created": "Er zijn geen servers aangemaakt",
    "menu_validate_configuration": "De configuratie bevestigen",
    "menu_wifi": "Wifi",
    "menu_select_at_least_one_automatic_detection": "Selecteer ten minste één automatische detectie",
    "menu_save_at_least_one_wifi_connection": "Registreer ten minste één wifinetwerk",
    "menu_configuration_update_has_failed": "De configuratie-update is mislukt",
    "menu_check_internet_connection_and_retry": "Controleer de internetverbinding en probeer het opnieuw",
    "menu_download_configuration_model_to_computer": "Download het configuratiemodel op de pc",
    "menu_configuration_model_name": "Naam van het configuratiemodel",
    "menu_device_notification": "Beheer van de apparaatmeldingen",
    "menu_password_for_deployment": "Wachtwoord voor de configuratie via installatie: 6 tekens waaronder ten minste 1 kleine letter, 1 hoofdletter en 1 cijfer, geen speciaal teken",
    "menu_enter_a_password": "Voer een wachtwoord in",
    "menu_password_must_be_different_from_access_password": "Het wachtwoord moet verschillen van het toegangswachtwoord",
    "menu_location_indoor_wifi": "Binnen via wifi",
    "menu_location_indoor_ble": "Buiten via bluetooth",
    "menu_location_automatic_incoming_call_pickup_after_three_rings": "Automatisch opnemen van inkomende oproepen na 3x overgaan",
    "menu_err_ble_level_precision_not_activated": "Verdiepingenbeheer met bluetooth-beacons is geactiveerd voor de locatiebepaling via wifi. Deze instelling is dus verplicht.",
    "menu_deployment_password": "Installatiewachtwoord",
    "menu_no_wifi_access_point_has_been_registered": "Er zijn geen wifi-access-points geregistreerd",
    "menu_no_ble_beacon_has_been_registered": "Er zijn geen bluetooth-beacons geregistreerd",
    "menu_deactivation_of_alert_system_by_the_user": "Uitschakeling van het alarmsysteem door de gebruiker",
    "menu_freely_disable_enable_alert_system": "Vrij in- of uitschakelen van het alarmsysteem toestaan",
    "menu_temporarily_disable_enable_alert_system": "Tijdelijk in- of uitschakelen van het alarmsysteem toestaan",
    "menu_duration_of_deactivation": "Duur van de uitschakeling",
    "menu_GSM_data_wifi_issue_notification": "Melding bij een probleem met het GSM-, data- en wifinetwerk",
    "menu_battery_monitoring": "Batterijcontrole",
    "menu_networks_monitoring": "Netwerkmonitoring",
    "menu_low_battery_notification_triggering_threshold": "Drempelwaarde voor melding laag batterijniveau",
    "menu_name_is_not_displayed": "Gebruikersnaam wordt niet weergegeven op het apparaat",
    "menu_confirm_deactivation_of_localisation": "Bevestig dat de locatiebepaling uitgeschakeld moet worden",
    "menu_ask_to_activate_sip": "Voice over IP (VoIP) via SIP-account activeren",
    "menu_wrong_sip_configuration": "Alle VoIP-velden moeten worden ingevuld",
    "menu_show_fragment_location": "De widget voor de huidige locatieweergave activeren",
    "menu_5_percent": "5%",
    "menu_10_percent": "10%",
    "menu_20_percent": "20%",
    "menu_name_is_displayed": "De gebruikersnaam wordt weergegeven op het apparaat",
    "menu_name_is_battery_percentage_is_displayed": "Het batterijpercentage weergeven",
    "menu_name_is_wake_up_screen_when_charging_enabled": "Activeer het scherm om de 10 seconden tijdens het opladen wanneer het horloge is ingeschakeld",
    "menu_name_is_sound_notif_securite_positive_enabled": "Activeer het meldingsgeluid"
  },
  "alerttrigerring": {
    "menu_enable_selecte_switch":"Selecteer ten minste één detectie",
    "menu_agression": "Overval",
    "menu_demande_secours": "Vragen voor hulp",
    "menu_message_alert": "Waarschuwingsbericht om te verzenden",
    "menu_communication_unidirectionnelle": "Eenrichtingscommunicatie (luidspreker uitgeschakeld)",
    "menu_communication_bidirectionnelle": "Tweerichtingscommunicatie",
    "menu_cycle_destinataires_télésurveillance": "Automatische oproepcyclus (interne ontvangers of externe bewakingsoproep)",
    "menu_sos_button_settings": "Instellingen SOS-knop",
    "menu_power_button_settings": "Instellingen aan/uit-knop",
    "menu_automatic_detection_settings": "Instellingen automatische detecties",
    "menu_trigger_mode": "Activeringsmodus",
    "menu_one_long_press": "Een lange druk op de knop",
    "menu_three_short_presses": "Drie keer snel drukken",
    "menu_alert_mode": "Alarmmeldingsmodus",
    "menu_speaker_on_request_for_help": "Luidspreker ingeschakeld > verzoek om hulp",
    "menu_silent_assault": "Stil > context van agressie",
    "menu_select_at_least_one_notification_mode": "Selecteer ten minste één meldingsmodus",
    "menu_front_programmable_button_settings": "Instellingen programmeerbare knop voorkant",
    "menu_side_black_button_settings": "Instellingen zwarte knop zijkant",
    "menu_side_yellow_button_settings": "Instellingen gele knop zijkant",
    "menu_side_programmable_button_settings": "Instellingen programmeerbare knop zijkant",
    "menu_sos_button_and_programmable_button_settings": "Instellingen SOS-knop en programmeerbare knoppen"
  },
  "locationoutdoor": {
    "menu_outdoor_location": "Instellingen locatiebepaling buiten",
    "menu_gps_based": "Locatiebepaling buiten via GPS",
    "menu_refreseh_only_when_event_sending": "Alleen updates wanneer een gebeurtenis wordt verzonden",
    "menu_update_at_regular_intervals": "Regelmatige updates",
    "menu_automatic_refresh_interval": "Frequentie van automatische updates",
    "menu_location_update_method": "Updatemethode locatiebepaling"
  },
  "locationindoorwifi": {
    "menu_err_ssid_already_exist": "Het SSID bestaat al in de lijst",
    "menu_no_ble_transparent": "Geen Bluetooth UUID is geregistreerd",
    "menu_no_wifi_hotspot_transparent": "Geen WiFi-SSID is geregistreerd",
    "menu_no_param_found_indoord": "Geen parameter is geselecteerd",
    "menu_list_ssid_filter": "Lijst met te filteren SSID's",
    "menu_add_ssid_filter": "Voeg SSID's toe om te filteren",
    "menu_wifi_based_indoor_location_transparent": "Indoor locatie gebaseerd op WiFi (Transparante modus)",
    "menu_disable_ble_loc_to_handle_floors": "Deze instelling moet worden uitgeschakeld op de instellingspagina van de locatiebepaling binnen via bluetooth",
    "menu_enable_ble_loc_to_handle_floors": "Deze instelling moet worden ingeschakeld op de instellingspagina van de locatiebepaling binnen via bluetooth",
    "menu_wifi_based_indoor_location": "Locatiebepaling binnen via wifi",
    "menu_enable_wifi_based_indoor_location": "Activeer de locatiebepaling binnen via wifi",
    "menu_import_a_new_wifi_hotspot_file": "Importeer een nieuw bestand met wifi-access-points in .csv-formaat",
    "menu_import_succeeded": "Import succesvol",
    "menu_confirm_import": "Bevestig de import",
    "menu_import_will_replace_existing_list": "Import vanuit een .csv-bestand zal de lijst met geregistreerde wifi-access-points vervangen.",
    "menu_error_while_importing": "Fout tijdens import",
    "menu_export_to_csv_wifi_configuration": "Alle wifi-access-points exporteren in .csv-formaat",
    "menu_excel_file_is_empty": "Het Excel-bestand is leeg",
    "menu_check_your_excel_file": ". Controleer uw Excel-bestand.",
    "menu_download_excel_file_template_for_importation": "Download het Excel-sjabloon voor de import",
    "menu_number_of_imported_wifi_hotspots": "Aantal geïmporteerde wifi-access-points: ",
    "menu_an_error_was_detected_line": "Er is een fout gedetecteerd regel ",
    "menu_number_of_registered_wifi_hotspots": "Aantal geregistreerde wifi-access-points: ",
    "menu_incorrect_column_headers": "Onjuiste kolomtitels. Gebruik het Excel-sjabloon om de wifi-access-points in te voeren en sla het bestand op als een .csv-bestand met een puntkomma als scheidingsteken.",
    "menu_wifi_hotspot_for_indoor_location": "Wifi-access-points voor locatiebepaling binnen",
    "menu_no_wifi_hotspot_recorded": "Er zijn geen wifi-access-points geregistreerd",
    "menu_no_wifi_hotspot_imported": "Er zijn geen wifi-access-points geïmporteerd",
    "menu_add_hotspot": "Voeg een wifi-access-point toe",
    "menu_modify_hotspot": "Wijzig een wifi-access-point",
    "menu_hotspot_ssid": "SSID",
    "menu_hotspot_bssid": "BSSID",
    "menu_hotspot_code_zone": "Zonecode",
    "menu_hotspot_level": "Verdieping",
    "menu_hotspot_description": "Beschrijving",
    "menu_tippy_ssid": "Naam wifinetwerk",
    "menu_tippy_bssid": "Uitgezonden MAC-adres van het wifi-access-point (6 paar hexadecimale tekens gescheiden door ':')",
    "menu_tippy_code_zone": "Informatie verzonden via het SIA-protocol",
    "menu_tippy_level": "0 voor begane grond, negatief getal voor kelders (bijv: -3)",
    "menu_tippy_description": "Locatie van het access point. Het is verboden om het '_' teken te gebruiken wanneer de optie om berichten naar Emerit-horloges te sturen is geactiveerd vanwege een bug (deze bug zal in de volgende update worden verholpen)",
    "menu_err_empty_ssid": "De SSID mag niet leeg zijn.",
    "menu_err_wrong_bssid": "Onjuiste BSSID. De BSSID moet een reeks van 6 hexadecimale tekens zijn, gescheiden door het teken ':'. Bijvoorbeeld: 11:22:33:44:55:66.",
    "menu_err_bssid_already_exist": "De ingevoerde BSSID is al opgeslagen.",
    "menu_err_wrong_level": "Fout bij het invoeren van de verdieping. Dit moet een positief of negatief getal zijn.",
    "menu_err_empty_desc": "De beschrijving mag niet leeg zijn.",
    "menu_err_wrong_code_zone": "De zonecode moet uit 3 cijfers bestaan en tussen 001 en 999 liggen. Bijvoorbeeld: 007.",
    "menu_add_hotspot_success": "Toevoegen wifi-access-point gelukt",
    "menu_err_one_elem_missing_level": "De verdieping is niet voor alle wifi-access-points ingevoerd. Controleer de geregistreerde lijst.",
    "menu_add_hotspot_failed": "Fout bij het toevoegen van een nieuw wifi-access-point",
    "menu_modify_hotspot_success": "Wijzigen wifi-access-point gelukt",
    "menu_level_precision_with_ble": "Beheer wijziging verdiepingen met bluetooth-beacons",
    "menu_enable_import_export_csv": "Importeren / exporteren in .csv-formaat",
    "menu_add_manually_wifi_hotspots": "Handmatig wifi-access-points toevoegen",
    "menu_remove_all_hotspots": "Weet u zeker dat u alle geregistreerde wifi-access-points wilt verwijderen?",
    "menu_remove_one_hotspot": "Weet u zeker dat u dit wifi-access-point wilt verwijderen?",
    "menu_precise_wifi_location_with_ble": "Wifi-locatiebepaling binnen verfijnd via bluetooth",
    "menu_title_refresh_choice":" Frequence d'actualisation"
  },
  "locationindoorble": {
    "menu_remove_one_beacon_transparent": "Weet je zeker dat je deze UUID wilt verwijderen?",
    "menu_add_beacon_transparent_success": "UUID succesvol toegevoegd",
    "menu_condition_uuid_transparent": "Het UUID-formaat moet 32 tekens zijn in hexadecimaal",
    "menu_add_beacon_transparent_failed": "UUID is al geregistreerd",
    "menu_list_ssid_filtered": "Lijst met te filteren SSID's",
    "menu_custom_model": "Andere UUID",
    "menu_ble_based_indoor_location_transparent": "Indoor locatie gebaseerd op Bluetooth (Transparante modus)",
    "menu_list_uuid_filtered": "Lijst met te filteren UUID's",
    "menu_add_uuid_filtered": "Voeg Company ID (UUID) toe om te filteren",
    "menu_ble_based_indoor_location": "Locatiebepaling binnen via bluetooth",
    "menu_enable_ble_based_indoor_location": "Lcatiebepaling binnen via bluetooth activeren",
    "menu_download_excel_file_template_for_importation": "Het Excelsjabloon voor de import downloaden",
    "menu_import_a_new_ble_beacons_file": "Een nieuw bestand met bluetooth-beacons in .csv-formaat importeren",
    "menu_import_succeeded": "Import gelukt",
    "menu_confirm_import": "Import bevestigen",
    "menu_import_will_replace_existing_list": "De import vanuit een .csv-bestand vervangt de lijst met geregistreerde bluetooth-beacons",
    "menu_error_while_importing": "Fout bij de import",
    "menu_excel_file_is_empty": "Het Excel-bestand is leeg",
    "menu_check_your_excel_file": ". Controleer uw Excel-bestand",
    "menu_export_to_csv_ble_configuration": "Alle Bluetooth-beacons exporteren in .csv-formaat",
    "menu_no_ble_beacon_recorded": "Er zijn geen bluetooth-beacons geregistreerd",
    "menu_no_ble_beacon_imported": "Er zijn geen bluetooth-beacons geïmporteerd",
    "menu_number_of_registered_ble_beacons": "Aantal geregistreerde bluetooth-beacons: ",
    "menu_incorrect_column_headers_ble": "Onjuiste kolomtitels. Gebruik het Excel-sjabloon om de Bluetooth-beacons in te voeren en sla het bestand op als een .csv-bestand met een puntkomma als scheidingsteken.",
    "menu_number_of_imported_ble_beacons": "Aantal geïmporteerde bluetooth-beacons: ",
    "menu_an_error_was_detected_line": "Er is een fout gedetecteerd regel ",
    "menu_add_beacon": "Een bluetooth-beacon toevoegen",
    "menu_modify_beacon": "Een bluetooth-beacon wijzigen",
    "menu_add_manually_ble_beacons": "Handmatig bluetooth-beacons toevoegen",
    "menu_remove_all_beacons": "Weet u zeker dat u alle geregistreerde bluetooth-beacons wilt verwijderen?",
    "menu_remove_one_beacon": "Weet u zeker dat u dit bluetooth-beacon wilt verwijderen?",
    "menu_add_beacon_success": "Toevoegen bluetooth-beacon gelukt",
    "menu_add_beacon_failed": "Fout bij het toevoegen van een nieuw bluetooth-beacon",
    "menu_modify_beacon_success": "Wijzigen bluetooth-beacon gelukt",
    "menu_beacon_model": "Model voor bluetooth-beacon",
    "menu_beacon_company_id": "Bedrijfs-ID",
    "menu_beacon_id": "Beacon-ID",
    "menu_beacon_type": "Type",
    "menu_beacon_level": "Verdieping",
    "menu_beacon_code_zone": "Zonecode",
    "menu_beacon_description": "Beschrijving",
    "menu_tippy_uuid": "Komt overeen met de UUID van het iBeacon-protocol, een reeks van 32 hexadecimale tekens",
    "menu_tippy_namespace_id": "Komt overeen met de namespace-ID van het Eddystone-protocol, een reeks van 20 hexadecimale tekens",
    "menu_tippy_bbe9_major_minor": "Komt overeen met de 8 hexadecimale tekens die op het bluetooth-beacon e-BBE9 staan afgedrukt",
    "menu_tippy_loc_ex_mac_address": "Komt overeen met het MAC-adres dat is afgedrukt op het Bluetooth LOC-EX 01-baken (6 paar hexadecimale tekens gescheiden door ':')",
    "menu_tippy_ibeacon_major_minor": "Komt overeen met de Major + Minor van het iBeacon-protocol, een reeks van 8 hexadecimale tekens",
    "menu_tippy_instance_id": "Komt overeen met de instance ID van het Eddystone-protocol, een reeks van 12 hexadecimale tekens",
    "menu_tippy_level": "0 voor begane grond, negatief getal voor kelders (bijv: -3)",
    "menu_tippy_code_zone": "Informatie verzonden via het SIA-protocol",
    "menu_tippy_description": "Locatiebepaling gekoppeld aan het beacon. Het is verboden om het '_' teken te gebruiken wanneer de optie om berichten naar Emerit-horloges te sturen is geactiveerd vanwege een bug (deze bug zal in de volgende update worden verholpen)",
    "menu_err_ibeacon_company_id": "Onjuiste bedrijfs-ID. Deze komt overeen met de UUID. Hij moet daarom bestaan uit 32 hexadecimale tekens.",
    "menu_err_ibeacon_beacon_id": "Onjuiste beacon-ID. Deze komt overeen met Major + Minor. Het moet dus bestaan uit 8 hexadecimale tekens.",
    "menu_err_eddystone_company_id": "Onjuiste bedrijfs-ID. Deze komt overeen met de namespace-ID. Hij moet dus bestaan uit 20 hexadecimale tekens.",
    "menu_err_eddystone_beacon_id": "Onjuiste beacon-ID. Deze komt overeen met de instance-ID. Het moet dus bestaan uit 12 hexadecimale tekens.",
    "menu_err_bbe9_beacon_id": "Onjuiste beacon-ID. Deze komt overeen met de 8 hexadecimale tekens die op het bluetooth-beacon e-BBE9 staan afgedrukt.",
    "menu_err_bbe9_already_exist": "Een bluetooth-beacon e-BBE9 met deze ID bestaat al.",
    "menu_err_loc_ex_beacon_id": "Verkeerde baken-ID. De baken-ID komt overeen met het MAC-adres dat op de LOC-EX 01 Bluetooth Beacon is afgedrukt.",
    "menu_err_loc_ex_already_exist": "Er bestaat al een Bluetooth LOC-EX 01-baken met deze identifier.",
    "menu_err_ibeacon_already_exist": "Er bestaat al een bluetooth iBeacon baken met deze UUID en deze Major Minor.",
    "menu_err_eddy_already_exist": "Er bestaat al een bluetooth Eddystone-beacon met deze namespace-ID en instance-ID.",
    "menu_err_wrong_level": "Fout bij het invoeren van de verdieping. Dit moet een positief of negatief getal zijn.",
    "menu_err_empty_desc": "De beschrijving mag niet leeg zijn.",
    "menu_err_wrong_code_zone": "De zonecode moet uit 3 cijfers bestaan en tussen 001 en 999 liggen. Bijvoorbeeld: 007.",
    "menu_level_precision_with_ble": "Wijziging verdiepingen beheren",
    "menu_err_one_elem_missing_level": "De verdieping is niet voor alle bluetooth-beacons ingevuld. Controleer de geregistreerde lijst.",
    "menu_err_wifi_level_precision_is_activated": "Het verdiepingenbeheer met bluetooth-beacons is geactiveerd voor locatiebepaling via wifi. Deze instelling is dus verplicht.",
    "menu_err_no_level_beacon": "Voor het verdiepingenbeheer moet ten minste één bluetooth-beacon van het type Level worden toegevoegd",
    "menu_enable_import_export_csv": "Importeren / exporteren in .csv-formaat",
    "menu_title_refresh_ble_choice": "Vernieuwingsfrequentie",
    "menu_scan_mode_fastest": "Verversen op vaste intervallen (6 seconden, hoog verbruik)",
    "menu_scan_mode_average": "Verversen op vaste intervallen (10 seconden, matig verbruik)",
    "menu_scan_mode_on_demand": "Verversen alleen bij de overdracht van een gebeurtenis",
    "menu_ble_new_algo_activation_title":"Configuratie van het algoritme",
    "menu_ble_new_algo_activation":"Activeer het nieuwe algoritme voor de lokalisatie van beacons"
  },
  "internalrecipients": {
    "menu_name_recipient": "Naam van ontvanger",
    "menu_duration_of_call_attempt_before_next_number": "Duur van de poging tot waarschuwing voordat naar het volgende nummer wordt overgeschakeld",
    "menu_event_select_call_and_or_sip_messaging": "Oproep en/of SIP-bericht selecteren",
    "menu_request_loc_with_sms_command": "Deze ontvanger kan de locatie opvragen met het volgende SMS-commando: ?LOC",
    "menu_sip_scenario": "Verzending van alarmmeldingen via SIP-account",
    "menu_alert_configuration_not_available_with_pec": "Wanneer het alarmscenario 'SMS met bevestiging, vervolgens oproep' is geactiveerd, gebeurt de configuratie van de ontvangst van alarmmeldingen automatisch.",
    "menu_sens_sms_with_sip_not_possible": "Het versturen van SMS-berichten naar een telefoonnummer via een SIP-account is niet beschikbaar. Alleen de oproep is beschikbaar.",
    "menu_err_sip_account_already_exist": "Er bestaat al een ontvanger met deze SIP-ID",
    "menu_receive_events_by_sip_messaging": "Deze ontvanger ontvangt de gebeurtenissen via een SIP-bericht",
    "menu_switch_watch_is_using_sip_account": "Deze ontvanger gebruikt een SIP-account",
    "menu_recipient_added_with_success": "Ontvanger toegevoegd",
    "menu_recipient_modified_with_success": "Ontvanger gewijzigd",
    "menu_modify_recipient": "Een ontvanger wijzigen",
    "menu_sip_account_id": "SIP-ID",
    "menU_sip_servel_url": "URL SIP-server",
    "menu_icon_sms_desc": ": Deze ontvanger ontvangt de alarmmeldingen via SMS",
    "menu_icon_phone_desc": ": Deze ontvanger ontvangt de alarmmeldingen via een oproep",
    "menu_icon_watch_desc": ": Deze ontvanger is een Emerit-horloge",
    "menu_icon_sip_desc": ": Deze ontvanger gebruikt een SIP-account",
    "menu_number_or_sip_id": "Oproepnummer / SIP-ID",
    "menu_remove_all_recipients": "Weet u zeker dat u alle geregistreerde ontvangers wilt verwijderen?",
    "menu_remove_one_recipient": "Weet u zeker dat u deze ontvanger wilt verwijderen?",
    "menu_add_recipient": "Een ontvanger toevoegen",
    "menu_sms_and_calls_cycle": "SMS-berichten en oproepen in cascade",
    "menu_sms_then_calls_cycle": "SMS-berichten, vervolgens oproepen in cascade",
    "menu_sms_with_acknowledgment_then_call": "SMS-berichten met bevestiging, vervolgens oproep",
    "menu_scenario_is": "Alarmscenario: ",
    "menu_settings": "Instellingen",
    "menu_nb_recipients_saved": "Aantal geregistreerde interne ontvangers: ",
    "menu_is_emerit_watch": "Deze ontvanger is een Emerit-horloge",
    "menu_internal_recipients": "Interne ontvangers",
    "menu_monitoring_center_call_numbers": "Oproepnummers van de alarmcentrale",
    "menu_recipient": "Ontvanger",
    "menu_enable_recipient": "De ontvanger activeren",
    "menu_call_number": "Oproepnummer",
    "menu_enable_call_number": "Het oproepnummer activeren",
    "menu_name": "Naam",
    "menu_phone_number_international_format": "Telefoonnummer (internationaal formaat)",
    "menu_receive_alerts_by_call": "Deze ontvanger ontvangt de alarmmeldingen via een oproep",
    "menu_receive_events_by_sms": "Deze ontvanger ontvangt de gebeurtenissen via SMS",
    "menu_event_alerts": "Alarmmeldingen",
    "menu_event_system_status": "Systeemstatus",
    "menu_event_charge_status": "Oplaadstatus",
    "menu_event_low_battery": "Batterij bijna leeg",
    "menu_event_beacon_ble_low_battery": "Bluetooth Beacon Batterij bijna leeg",
    "menu_event_select_call_and_or_sms": "Selecteer oproep en/of SMS",
    "menu_nfc_reading": "NFC-tag lezen"
  },
  "internalrecipientssettings": {
    "menu_internal_recipients_settings": "Instellingen interne ontvangers",
    "menu_sms_language_selection": "Taal van de SMS-berichten selecteren",
    "menu_alert_scenario_type": "Alarmscenario selecteren",
    "menu_sms_and_calls_cycle": "SMS-berichten en oproepen in cascade",
    "menu_sms_then_calls_cycle": "SMS-berichten, vervolgens oproepen in cascade",
    "menu_sms_with_acknowledgment_then_call": "SMS-berichten met bevestiging, vervolgens oproep",
    "menu_maximum_waiting_time_for_acknowledgment_reception": "Maximale wachttijd voor ontvangst van de bevestigings-SMS",
    "menu_call_cycle_stopped_by_sms": "De oproepcyclus via SMS stoppen",
    "menu_show_sms_data_selection": "De selectie van SMS-gegevens weergeven",
    "menu_sms_data_selection": "Selectie van SMS-gegevens",
    "menu_basic_data": "Basisgegevens",
    "menu_gps_data": "GPS-gegevens",
    "menu_device_user": "Gebruiker van het apparaat",
    "menu_battery_level": "Batterijniveau",
    "menu_event_timestamp": "Tijdstempel van de gebeurtenis",
    "menu_google_map_link": "Link Google Maps",
    "menu_timestamp": "Tijdstempel",
    "menu_adress_google_reverse_geocoding": "Adres (Google reverse geocoding)",
    "menu_accuracy": "Nauwkeurigheid",
    "menu_coordinates_DMS_format": "Coördinaten in DMS-formaat",
    "menu_coordinates_DD_format": "Coördinaten in DD-formaat",
    "menu_alert_labels_customization": "Labels alarmmeldingen aanpassen",
    "menu_show_alert_labels_customization": "Aanpassing labels alarmmeldingen weergeven",
    "menu_request_for_help": "verzoek om hulp (standaard)",
    "menu_assault": "agressie (standaard)",
    "menu_no_movement": "immobiliteit (standaard)",
    "menu_tilt": "kanteling (standaard)"
  },
  "sipconfiguration": {
    "menu_title": "VOIP",
    "menu_sip_need_configuration": "Configuratie VOIP",
    "menu_account_name": "Beschrijving van het account",
    "menu_user_name": "Gebruikersnaam",
    "menu_user_password": "Wachtwoord",
    "menu_server_url": "Url van de server"
  },
  "ipserversettings": {
    "menu_std_gps_timestamp(O)": "[H] GPS-positie tijdstempel",
    "menu_extended_data_T2i_format_selection": "Uitgebreide gegevensselectie in T2i©-formaat",
    "menu_T2i_format": "T2i©-Formaat ",
    "menu_sia_protocol_settings": "Instellingen SIA DC-09 protocol",
    "menu_encapsulated_alarm_protol": "Ingekapseld alarmprotocol",
    "menu_admeco_cid_contact_id": "Ademco Contact ID (CID)",
    "menu_sia_dcs": "SIA DCS",
    "menu_extended_data_format_and_selection": "Formaat en selectie van uitgebreide gegevens",
    "menu_standard_format": "Standaardformaat",
    "menu_esi_format": "ESI©-formaat",
    "menu_add_the_message_ending_timestamp": "Voeg het tijdstempel aan het einde van het bericht toe",
    "menu_extended_data_standard_format_selection": "Selectie van uitgebreide gegevens in standaardformaat",
    "menu_extended_data_esi_format_selection": "Selectie van uitgebreide gegevens in ESI©-formaat",
    "menu_std_battery": "[B] Batterijniveau",
    "menu_std_event_timestamp": "[H] tijdstempel gebeurtenis",
    "menu_std_imei": "[D] IMEI van het apparaat",
    "menu_std_beacon_id": "[C] beacon-ID locatiebepaling wifi / bluetooth",
    "menu_std_gps_accuracy": "[M] nauwkeurigheid GPS-positie",
    "menu_std_gps_timestamp": "[O] tijdstempel GPS-positie",
    "menu_std_gps_speed": "[S] snelheid GPS-positie",
    "menu_std_gps_validity": "[V] geldigheid GPS-positie",
    "menu_std_gps_altitude": "[Z] hoogte GPS-positie",
    "menu_esi_battery_level": "$B Batterijniveau",
    "menu_esi_gps_timestamp": "$T tijdstempel GPS-positie",
    "menu_gps_coordinates_format": "Formaat GPS-coördinaten",
    "menu_gps_DD": "DD Decimale graden",
    "menu_gps_DDM": "DM Graden decimale minuten",
    "menu_customed_field": "Aangepast veld",
    "menu_lpref": "Lpref",
    "menu_monitoging_server_sia_protocol_settings": "Instellingen SIA DC-09 protocol voor controleserver",
    "menu_monitoring_center_server_sia_protocol_settings": "Instellingen SIA DC-09 protocol voor server alarmcentrale",
    "menu_location_maximum_age": "Maximale leeftijd van de locatiebepaling",
    "menu_location_maximum_accuracy": "Maximale nauwkeurigheid van de locatiebepaling"
  },
  "ipserver": {
    "menu_unit_description_message":"Berichten",
    "menu_event_end_tracking_stop_post_alert":"Einde van de tracking bij Post-alert stop",
    "menu_event_end_tracking_after_x_message":"Einde van de tracking na het versturen van",
    "menu_event_permanents":"Permanent",
    "menu_event_trigger_alert":"Bij het activeren van een alarm",
    "menu_enable_link_test":"Linktest inschakelen",
    "menu_link_test":"Linktest",
    "menu_alarm_monitoring_center_server": "Server van het alarmbewakingscentrum",
    "menu_enable_main_server": "Hoofdserver inschakelen",
    "menu_main_server": "Hoofdserver",
    "menu_events_to_be_transmitted": "Te verzenden gebeurtenissen",
    "menu_client_id": "Klant-ID",
    "menu_ip_url": "IP-adres of URL",
    "menu_port": "Poortnummer",
    "menu_enable_cyclic_test": "Tracking inschakelen",
    "menu_cyclic_test": "Tracking",
    "menu_test_interval": "Verzendinterval",
    "menu_backup_server": "Back-upserver",
    "menu_backup_sms": "SMS-back-up",
    "menu_enable_backup_server": "Back-upserver inschakelen",
    "menu_enable_backup_sms": "SMS-back-up inschakelen",
    "menu_port_must_be_between_0_and_65536": "Het poortnummer moet tussen 0 en 65536 liggen"
  },
  "wearingways": {
    "menu_waist_worn": "op de taille dragen",
    "menu_enable_no_movement_detection": "Immobiliteitsdetectie activeren",
    "menu_enable_tilt_detection": "Kanteldetectie activeren",
    "menu_no_movement_detection": "Immobiliteitsdetectie",
    "menu_tilt_detection": "Kanteldetectie",
    "menu_detection_duration_before_prealert": "Detectieduur vóór pre-alarmmelding",
    "menu_detection_threshold": "Detectiedrempel",
    "menu_detection_mode": "Detectiemodus",
    "menu_tilt_only": "Alleen kantelen",
    "menu_tilt_without_movement": "Kantelen zonder beweging",
    "menu_select_a_wearing_way_and_modify_settings_if_needed": "Selecteer een poort en wijzig de instellingen indien nodig"
  },
  "wristalert": {
    "menu_noise_mode": "Luidspreker ingeschakeld > verzoek om hulp",
    "menu_discret_mode": "Stil > context van agressie",
    "menu_choose_alert_mode": "Alarmmodus",
    "menu_title_wrist_alert": "Instelling alarm via polsbeweging",
    "menu_choose_threshold": "Intensiteit van de beweging om het alarm te activeren",
    "menu_thresold_value_low": "Langzaam",
    "menu_thresold_value_middle": "Gemiddeld (aanbevolen)",
    "menu_thresold_value_high": "Heel snel"
  },
  "login": {
    "menu_first_device_boot_error": "Als dit de eerste keer is dat een apparaat wordt geconfigureerd, moet een inbedrijfstellingsprocedure worden gevolgd voordat het apparaat kan worden geconfigureerd.",
    "menu_quick_start_guide": "Online hulp",
    "menu_home_help": "https://magneta.document360.io/v1/en",
    "menu_connexion_error": "Er is een probleem met de verbinding opgetreden. Controleer uw internetverbinding.",
    "menu_enter_five_last_digits_of_serial_number": "Voer de 5 laatste cijfers van het serienummer (SN) in",
    "menu_enter_five_last_digits_of_imei_number": "Voer de 5 laatste cijfers van het IMEI-nummer in",
    "menu_login": "Verbinding",
    "menu_select_device_model": "Selecteer het apparaatmodel",
    "menu_please_select_the_device_model": "Selecteer eerst het apparaatmodel",
    "menu_entered_numbers_dont_match_with_available_device": "De ingevoerde nummers komen niet overeen met een apparaat of er is een verbindingsprobleem opgetreden.",
    "menu_please_read_quick_start_guide_first": "Lees de snelstartgids.",
    "menu_configure_this_device": "Dit apparaat configureren",
    "menu_configuration_models_management": "Beheer configuratiemodellen",
    "menu_deploy_a_configuration_model": "Een configuratiemodel uitrollen",
    "menu_invalid_file": "Ongeldig bestand",
    "menu_only_file_with_emerit_extension_are_supported": "Alleen bestanden met de extensie .emerit worden in aanmerking genomen",
    "menu_create_configuration_model": "Een configuratiemodel aanmaken",
    "menu_modify_a_configuration_model": "Een configuratiemodel wijzigen",
    "menu_drag_and_drop_file_or_click_to_select": "Sleep of klik om het bestand configuratiemodel te selecteren",
    "menu_configuration_model": "Configuratiemodel",
    "menu_configuration_model_creation": "Een configuratiemodel aanmaken",
    "menu_connect_to_a_device": "Maak verbinding met een apparaat",
    "menu_models_dont_match": "Het geselecteerde bestand voldoet niet of het configuratiemodel is niet compatibel met het geselecteerde model.",
    "menu_select_language": "Taal selecteren",
    "menu_redirection_page_stay_emerit_console":"Blijf op Emerit Console",
    "menu_redirect_emerit_services":"Ga naar Emerit Services",
    "menu_redirection_delivery":"Alle Emerit-apparaten die sinds 1 januari 2025 zijn geleverd, moeten worden geconfigureerd via het nieuwe Emerit Services-configuratieplatform op het volgende adres: ",
    "menu_redirection_help_redirect_mailto":"Heb je vragen? Neem gerust contact op met onze support via ",
    "menu_redirection_page_avertissement":"Waarschuwing",
    "menu_redirection_page_info":"Alle apparaten die vóór 1 januari 2025 via het Emerit Console-platform zijn geconfigureerd, kunnen op elk moment worden overgezet naar het nieuwe Emerit Services-platform op het volgende adres: "
  },
  "wifi": {
    "menu_mode_utilisation": "Bijwerken van de tijdzone volgens gebruiksmode",
    "menu_select_utc": "Selecteer een tijdzone",
    "menu_error_utc_none": "Selecteer een tijdzone, alstublieft",
    "menu_mode_wifi_alone": "Alleen WiFi:",
    "menu_mode_gsm_plus_wifi": "WiFi + GSM (SIM-kaart): automatische update",
    "menu_certificate_upload_success": "Certificaat succesvol geüpload",
    "menu_certificate_upload_fail": "Certificaat downloaden mislukt",
    "menu_err_pls_upload_certificate": "Download een certificaat",
    "menu_err_only_pfx_p12_accepted": "Alleen certificaten in .pfx/.p12-formaat worden aanvaard.",
    "menu_err_certificate_is_too_heavy": "De grootte van het certificaat is te groot, het mag niet groter zijn dan 5MB.",
    "menu_upload_certificat": "Importeer het PEAP-certificaat (.pfx/.p12)",
    "menu_certificate_password": "Wachtwoord",
    "menu_certificate_identifiant": "Identifiant",
    "menu_certificate_type": "CA Certificat",
    "menu_name_is_update_app_by_wifi_enabled": "Alleen configuraties ophalen en updates installeren als wifi actief is",
    "menu_use_wifi_connection": "Gebruik de wifiverbinding om alarmmeldingen te verzenden",
    "menu_config_wifi_network": "Wifinetwerken beheren",
    "menu_nb_wifi_network_saved": "Aantal geregistreerde wifinetwerken: ",
    "menu_send_alert_with_wifi": "Het wifinetwerk, indien beschikbaar, krijgt voorrang op het GSM-netwerk",
    "menu_network_added_with_success": "Wifinetwerk toegevoegd",
    "menu_network_modified_with_success": "Wifinetwerk gewijzigd",
    "menu_remove_all_networks": "Weet u zeker dat u alle geregistreerde wifinetwerken wilt verwijderen?",
    "menu_remove_one_network": "Weet u zeker dat u dit wifinetwerk wilt verwijderen?",
    "menu_registred_wifi_networks_list": "Lijst van geregistreerde wifinetwerken - alleen 2.4 GHz",
    "menu_ssid": "Naam van het SSID-netwerk",
    "menu_security_type": "Beveiligingstype",
    "menu_password": "Wachtwoord",
    "menu_add_a_wifi_network": "Een wifinetwerk toevoegen",
    "menu_modify_a_wifi_network": "Een wifinetwerk wijzigen",
    "menu_do_you_want_to_remove_this_network": "Wilt u dit netwerk verwijderen?",
    "menu_please_select_security_type": "Selecteer een beveiligingstype",
    "menu_hidden": "Verborgen netwerk",
    "menu_network_already_exists": "Het netwerk bestaat al"
  },
  "whitelist": {
    "menu_add_a_recipient_whitelist": "Een extra inkomend nummer toevoegen",
    "menu_modify_a_recipient_whitelist": "Een extra inkomend nummer wijzigen",
    "menu_recipient_whitelist_modified_with_success": "Extra inkomend nummer wijzigen gelukt",
    "menu_recipient_whitelist_added_with_success": "Extra inkomend nummer toevoegen gelukt",
    "menu_nb_recipient_whitelist": "Extra inkomende nummers: ",
    "menu_nb_recipient_mirror": "Ontvangers alarmmeldingen: ",
    "menu_remove_all_recipient_whitelist": "Weet u zeker dat u alle extra inkomende nummers van de witte lijst wilt verwijderen?",
    "menu_remove_one_recipient_whitelist": "Weet u zeker dat u dit extra inkomende nummer van de witte lijst wilt verwijderen?",
    "menu_add_whitelist_recipient": "Een inkomend nummer toevoegen",
    "menu_nb_total_whitelist_recipient": "Totaal aantal toegestane inkomende nummers in de witte lijst: ",
    "menu_whitelist_title": "Configuratie van de witte lijst"
  },
  "customscreens": {
    "menu_img_upload_info": "De grootte van een .png/.gif-afbeelding mag niet groter zijn dan 1 MB. De schermresolutie van het horloge is 128x96 pixels. Voor een optimale weergave van de afbeelding, respecteer de hoogte/schermbreedte (1,33). Als de verhouding niet wordt gerespecteerd, wordt de afbeelding automatisch verkleind.",
    "menu_err_pls_upload_launch_screen": "Download een afbeelding.",
    "menu_err_pls_upload_widget_screen": "Download een afbeelding of voer een tekst in.",
    "menu_err_only_png_gif_accepted": "Alleen afbeeldingen in .png/.gif formaat worden geaccepteerd.",
    "menu_err_img_is_too_heavy": "De afbeelding is te groot, deze mag niet groter zijn dan 1 MB.",
    "menu_img_upload_success": "Downloaden van de afbeelding is gelukt",
    "menu_img_upload_fail": "Downloaden van de afbeelding is mislukt",
    "menu_custom_screens_overview": "Preview",
    "menu_remove_launch_img": "Afbeelding verwijderen",
    "menu_remove_widget_img": "Achtergrondafbeelding verwijderen",
    "menu_display_widget_text_in_black": "De tekst in zwart weergeven",
    "menu_custom_widget_top_text": "Hoge tekst (maximaal 18 tekens, inclusief spaties)",
    "menu_custom_widget_bottom_text": "Lage tekst (maximaal 18 tekens, inclusief spaties)",
    "menu_upload_launch_screen_img": "Een nieuwe afbeelding downloaden (.png/.gif)",
    "menu_upload_widget_screen_img": "Een nieuwe achtergrondafbeelding downloaden (.png/.gif)",
    "menu_title_yes_custom_launch_screen": "Boot image configureren",
    "menu_title_no_custom_launch_screen": "Boot image aanpassen",
    "menu_title_yes_custom_widget_screen": "Contactwidget configuren",
    "menu_title_no_custom_widget_screen": "Een contactwidget toevoegen",
    "menu_custom_screens_title": "De weergave aanpassen"
  },
  "password": {
    "menu_create_new_password_for_model": "Voer een wachtwoord van 6 tekens in met ten minste 1 kleine letter, 1 hoofdletter en 1 cijfer, geen speciale tekens. Dit wachtwoord wordt gevraagd telkens wanneer dit model wordt gewijzigd.",
    "menu_enter_password": "Wachtwoord invoeren",
    "menu_enter_deployment_password": "Installatiewachtwoord invoeren",
    "menu_create_new_password": "Voer een wachtwoord van 6 tekens in met ten minste 1 kleine letter, 1 hoofdletter en 1 cijfer, geen speciale tekens. Dit wachtwoord wordt bij iedere verbinding met dit apparaat gevraagd.",
    "menu_confirm_password": "Wachtwoord bevestigen",
    "menu_wrong_password": "Onjuist wachtwoord",
    "menu_please_retry": "Probeer het opnieuw",
    "menu_empty_password": "Wachtwoord is leeg",
    "menu_passwords_dont_match": "De wachtwoorden komen niet overeen",
    "menu_at_least_one_password_is_empty": "Ten minste één wachtwoord is leeg",
    "menu_password_dont_meet_requirements": "Het wachtwoord voldoet niet aan de vereisten",
    "menu_modify_password": "Wachtwoord wijzigen",
    "menu_password_creation_warning": "Door u te verbinden met Emerit-console en het wachtwoord aan te maken, wordt u de feitelijke beheerder van het hierboven geïdentificeerde apparaat"
  },
  "contact": {
    "menu_contact_link": "https://www.magneta.fr/contactez-nous/",
    "menu_if_you_have_any_question_please_click_on_the_folowing_button": "Als u vragen heeft over het gebruik of de configuratie, klik dan op de volgende knop:",
    "menu_technical_support_email": "E-mail van de technische ondersteuning:",
    "menu_online_support_request_form": "Formulier verzoek online ondersteuning"
  },
  "deploy": {
    "menu_upload_failed": "Deployment mislukt, controleer de internetverbinding en probeer het opnieuw",
    "menu_device_updated": "Apparaat bijgewerkt",
    "menu_fill_server_ids": "Configureren van client-ID's",
    "menu_fill_personal_fields": "Vul de gepersonaliseerde gegevens in",
    "menu_target_a_device": "Te configureren apparaat",
    "menu_client_id_for_tls_main_server": "Klant-ID voor de hoofdserver van de alarmcentrale",
    "menu_client_id_for_tls_backup_server": "Klant-ID voor de secundaire server van de alarmcentrale",
    "menu_client_id_for_supervision_main_server": "Klant-ID voor de hoofdcontroleserver",
    "menu_client_id_for_supervision_backup_server": "Klant-ID voor de secundaire controleserver",
    "menu_from_a_configuration_model": "Vanaf het configuratiemodel",
    "menu_five_last_digits_of_serial_number": "5 laatste cijfers van het serienummer (SN)",
    "menu_five_last_digits_of_imei_number": "5 laatste cijfers van het IMEI-nummer",
    "menu_deploy_not_allowed": "U bent niet geautoriseerd om dit apparaat via installatie te configureren"
  },
  "side_menu": {
    "configuration": "Configuratie",
    "info": "Handleidingen",
    "contact": "Contact"
  },
  "watchhelp": {
    "menu_custom_screens_view": "https://www.support.magneta.fr/docs/en/watch-system-options#ii-customize-screen",
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_wifi_connection_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#i-use-wifi-connection",
    "menu_sip_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#ii-activate-voice-over-ip-voip-with-sip-account",
    "menu_whitelist_view": "https://www.support.magneta.fr/docs/en/watch-communication-options#iii-whitelist-incoming-call-limitation",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/watch-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/watch-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/watch-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/watch-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/watch-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/watch-main-page#vi-battery-monitoring",
    "menu_device_notif_management": "https://www.support.magneta.fr/docs/en/watch-main-page#vii-device-notification-management",
    "menu_system_option": "https://www.support.magneta.fr/docs/en/watch-main-page#viii-system-options",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/watch-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/watch-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/watch-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/watch-commissioning#iv-first-boot"
  },
  "phonehelp": {
    "menu_locex_help": "https://www.support.magneta.fr/docs/en/beacon-loc-ex-01",
    "menu_device_user_name": "https://www.support.magneta.fr/docs/en/smartphones-main-page#i-device-user-name",
    "menu_alerts_processing": "https://www.support.magneta.fr/docs/en/smartphones-main-page#ii-alerts-processing",
    "menu_alerts_triggering": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iii-alerts-triggering-mode",
    "menu_loc": "https://www.support.magneta.fr/docs/en/smartphones-main-page#iv-alerts-localization",
    "menu_communication_option": "https://www.support.magneta.fr/docs/en/smartphones-main-page#v-communication-options",
    "menu_battery_monitoring": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vi-battery-monitoring",
    "menu_deactivate_alert_system": "https://www.support.magneta.fr/docs/en/smartphones-main-page#vii-alert-system-deactivation-by-the-user",
    "menu_level_types_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9#associating-a-type-to-beacons",
    "menu_bbe9_help": "https://www.support.magneta.fr/docs/en/beacon-e-bbe9",
    "menu_add_personal_info": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuring-personal-settings",
    "menu_enter_deploy_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#entering-the-deployment-password",
    "menu_enter_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#templates-modification",
    "menu_create_template_password": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#configuration-template-creation",
    "menu_model_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#ii-multidevice-setup",
    "menu_deploy_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#iii-deployment-of-a-configuration",
    "menu_enter_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#enter-password",
    "menu_create_password_normal_login": "https://www.support.magneta.fr/docs/en/smartphones-configuration-methods#password-creation",
    "menu_loc_outdoor": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#i-gps-outdoor-location",
    "menu_loc_indoor_wifi": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#ii-wifi-indoor-location",
    "menu_loc_indoor_ble": "https://www.support.magneta.fr/docs/en/smartphones-alerts-localization#iii-bluetooth-indoor-location",
    "menu_main_page": "https://www.support.magneta.fr/docs/en/smartphones-main-page",
    "menu_first_boot": "https://www.support.magneta.fr/docs/en/smartphones-commissioning#ii-first-boot"
  }
}
