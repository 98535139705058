<template>
  <div>
    <div class="sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex py-5 xl:py-0">
          <div v-if="shouldPrintLogin"
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-3/5"
          >
            <SelectLanguageAndDevice
              v-model:language="language"
              v-model:device="device"
              :error-msg="selectErrorMsg"
            />
            <CustomButton
              text="login.menu_create_configuration_model"
              custom-class="btn btn-primary w-full mt-6"
              @click="onCreateModelButtonClicked"
            />
            <ButtonUploadFile
              v-for="(text, index) in uploadButtonsTexts"
              :key="index"
              :disabled="disableButtons"
              :accept="getAcceptedFileTypes"
              :text="text"
              custom-class="btn btn-primary w-full mt-5"
              @click="onUploadButtonClick"
              @file-uploaded="onFileSelected(index, $event[0])"
            />
            <CustomButton
              text="login.menu_connect_to_a_device"
              custom-class="btn btn-primary w-full mt-5"
              @click="onLoginButtonClicked"
            />
             <a href= "https://app.emerit-services.com">
                <CustomButton text="Continuer sur Emerit Services" custom-class="w-full btn btn-success mt-4" 
                />
              </a>
          </div>

           <FirstPageRedirectEmeritServices
          :shouldPrintLogin = "shouldPrintLogin"
          @emeritConsoleButtonClicked="shouldPrintLogin= true"
          />
        </div>
      </div>
      <CustomToast
        ref="CustomToastWrongFileUploaded"
        toast-id="custom-toast-wrong-file"
        text="login.menu_models_dont_match"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        help-text="common.menu_see_help_for_more_info"
        :help-link="$t(deviceStr + 'help.menu_model_login')"
      />
      <CustomToast
        ref="CustomToastDbDownloadError"
        toast-id="custom-toast-error-db-download"
        text="login.menu_connexion_error"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useStore } from "@/store";
import { migrate } from "../../dao/migrations";
import FirstPageRedirectEmeritServices from "../emerit-login/local-components/FirstPageRedirectEmeritServices.vue";
import {
  STORE_SET_PATH_DISCONNECTION,
  STORE_UPDATE_LOGIN_STATUS,
  STORE_SAVE_DEVICE_INFO,
  STORE_GET_DB_FILE_FROM_S3,
  STORE_SET_MODEL_FILE_AS_TEMPLATE_CONF
} from "../../store/const_store";
import {
  PATH_LOGIN,
  PATH_MODEL_LOGIN,
  PATH_PASSWORD,
  PATH_CREATE_PASSWORD,
  PATH_DEPLOY_LOGIN
} from "../../utils/const_path";

import { fetchSecrets } from "../../utils/secretManager";

const DEFAULT_IMEI = "00000";
const DEFAULT_SN = "00000";


const I18_MODIFY_MODEL = "login.menu_modify_a_configuration_model";
const I18_DEPLOY_MODEL = "login.menu_deploy_a_configuration_model";

const I18_ERR_PLEASE_SELECT_DEVICE =
  "login.menu_please_select_the_device_model";

const UPLOAD_BTN_TYPE_MODIFY = 0;
const UPLOAD_BTN_TYPE_DEPLOY = 1;

export default defineComponent({

   components: {
    FirstPageRedirectEmeritServices
  },

  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },
  data() {
    return {
      shouldPrintLogin :false,
      language: this.$i18n.locale,
      device: "",
      deviceModelName: "",
      selectErrorMsg: "",
      uploadButtonsTexts: [I18_MODIFY_MODEL, I18_DEPLOY_MODEL],
      disableButtons: true,
      imageSource: require(`@/assets/images/sigma-emerit-web.png`),
      deviceStr: useStore().state.emeritValues.deviceStr
    };
  },

  computed: {
    getAcceptedFileTypes() {
      return ".zip,.emerit_" + this.deviceModelName;
    }
  },

  watch: {
    language(newVal) {
      this.$i18n.locale = newVal;
    },
    device() {
      this.updateDeviceInfo();
      this.selectErrorMsg = "";
      this.imageSource = useStore().state.emeritValues.modelImageSource;
      this.deviceStr = useStore().state.emeritValues.deviceStr;
      this.disableButtons = false;
      this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName.toLowerCase();
    }
  },

  created() {
    fetchSecrets();
  },

  methods: {
    onCreateModelButtonClicked() {
      if (!this.device.length) {
        this.selectErrorMsg = I18_ERR_PLEASE_SELECT_DEVICE;
      } else {
        this.$store.dispatch(STORE_GET_DB_FILE_FROM_S3, {
          self: this
        });
      }
    },

    onUploadButtonClick() {
      if (!this.device.length)
        this.selectErrorMsg = I18_ERR_PLEASE_SELECT_DEVICE;
    },

    onFileSelected(uploadBtnType, modelFile) {
      const disconnectionPath =
        uploadBtnType === UPLOAD_BTN_TYPE_MODIFY
          ? PATH_MODEL_LOGIN
          : PATH_DEPLOY_LOGIN;
      this.$store.commit(STORE_SET_PATH_DISCONNECTION, disconnectionPath);
      this.$store.dispatch(STORE_SET_MODEL_FILE_AS_TEMPLATE_CONF, {
        self: this,
        modelFile: modelFile
      });
    },

    onDbDownloadCompleted() {
      migrate();
      this.$store.commit(STORE_SET_PATH_DISCONNECTION, PATH_MODEL_LOGIN);
      this.$router.push(PATH_CREATE_PASSWORD);
    },

    onTempConfUpdateSuccess() {
      migrate();
      this.$router.push(PATH_PASSWORD);
    },

    onTempConfUpdateFailed() {
      this.$refs.CustomToastWrongFileUploaded.trigger();
    },

    onDbDownloadError() {
      this.$store.commit(STORE_UPDATE_LOGIN_STATUS, false);
      this.$refs.CustomToastDbDownloadError.trigger();
    },

    onLoginButtonClicked() {
      if (!this.device.length) {
        this.selectErrorMsg = I18_ERR_PLEASE_SELECT_DEVICE;
      } else {
        this.$store.commit(STORE_SET_PATH_DISCONNECTION, PATH_MODEL_LOGIN);
        this.$router.push(PATH_LOGIN);
      }
    },

    updateDeviceInfo() {
      const deviceInfo = {
        deviceModelName: this.device,
        deviceIMEI: DEFAULT_IMEI,
        deviceSerialNumber: DEFAULT_SN
      };
      this.$store.commit(STORE_SAVE_DEVICE_INFO, deviceInfo);
    }
  }
});
</script>
