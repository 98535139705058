<template>
  <div>
    <div class="sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex py-5 xl:py-0">
          <div v-if="shouldPrintLogin"
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-3/5"
          >
            <SelectLanguageAndDevice
              v-model:language="language"
              v-model:device="device"
              :error-msg="selectErrorMsg"
            />
            <ButtonUploadFile
              text="login.menu_deploy_a_configuration_model"
              :disabled="disableUploadButton"
              :accept="getAcceptedFileTypes"
              custom-class="btn btn-primary w-full mt-5"
              @click="onUploadButtonClick"
              @file-uploaded="onFileSelected($event[0])"
            />

             <a href= "https://app.emerit-services.com">
                <CustomButton text="Continuer sur Emerit Services" custom-class="w-full btn btn-success mt-4" 
                  />
              </a>

          </div>

            <FirstPageRedirectEmeritServices
              :shouldPrintLogin = "shouldPrintLogin"
              @emeritConsoleButtonClicked="shouldPrintLogin= true"
            />
        </div>
      </div>
      <CustomToast
        ref="CustomToastWrongFileUploaded"
        toast-id="custom-toast-wrong-file"
        text="login.menu_models_dont_match"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        help-text="common.menu_see_help_for_more_info"
        :help-link="$t(deviceStr + 'help.menu_deploy_login')"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { migrate } from "../../dao/migrations";
import { useStore } from "@/store";
import {
  STORE_SAVE_DEVICE_INFO,
  STORE_SET_MODEL_FILE_AS_TEMPLATE_CONF
} from "../../store/const_store";
import { PATH_DEPLOY_LOGIN, PATH_PASSWORD } from "../../utils/const_path";
import FirstPageRedirectEmeritServices from "../emerit-login/local-components/FirstPageRedirectEmeritServices.vue";

import { fetchSecrets } from "../../utils/secretManager";

const I18_ERR_PLEASE_SELECT_DEVICE =
  "login.menu_please_select_the_device_model";

const STORE_SET_PATH_DISCONNECTION = "emeritValues/setDisconnectionPath";

export default defineComponent({
   components: {
    FirstPageRedirectEmeritServices
  },

  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },

  data() {
    return {
       shouldPrintLogin :false,
      language: this.$i18n.locale,
      device: "",
      deviceModelName: "",
      selectErrorMsg: "",
      disableUploadButton: true,
      imageSource: require(`@/assets/images/sigma-emerit-web.png`),
      deviceStr: useStore().state.emeritValues.deviceStr
    };
  },

  created() {
    fetchSecrets();
  },

  computed: {
    getAcceptedFileTypes() {
      return ".zip,.emerit_" + this.deviceModelName;
    }
  },

  watch: {
    language(newVal) {
      this.$i18n.locale = newVal;
    },

    device() {
      this.updateDeviceInfo();
      this.disableUploadButton = false;
      this.selectErrorMsg = "";
      this.imageSource = useStore().state.emeritValues.modelImageSource;
      this.deviceStr = useStore().state.emeritValues.deviceStr;
      this.deviceModelName = useStore().state.emeritValues.deviceInfo.deviceModelName.toLowerCase();
    }
  },

  methods: {
    onFileSelected(modelFile) {
      this.$store.dispatch(STORE_SET_MODEL_FILE_AS_TEMPLATE_CONF, {
        self: this,
        modelFile: modelFile
      });
    },

    onUploadButtonClick() {
      if (!this.device.length)
        this.selectErrorMsg = I18_ERR_PLEASE_SELECT_DEVICE;
    },

    onTempConfUpdateSuccess() {
      this.$store.commit(STORE_SET_PATH_DISCONNECTION, PATH_DEPLOY_LOGIN);
      migrate();
      this.$router.push(PATH_PASSWORD);
    },

    onTempConfUpdateFailed() {
      this.$refs.CustomToastWrongFileUploaded.trigger();
    },

    updateDeviceInfo() {
      const deviceInfo = {
        deviceModelName: this.device,
        deviceIMEI: "",
        deviceSerialNumber: ""
      };
      this.$store.commit(STORE_SAVE_DEVICE_INFO, deviceInfo);
    }
  }
});
</script>
