<template>
  <div>
    <div  class="sm:px-10" >
      <div class="block xl:grid grid-cols-2 gap-4">
        <LoginInfo :image-source="imageSource" />
        <div class="h-screen xl:h-auto flex py-5 xl:py-0">
          <div v-if="shouldPrintLogin"
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-3/5"
          >
            <SelectLanguageAndDevice v-model:language="language" v-model:device="device" :error-msg="selectErrorMsg" />
            <div class="border rounded p-5 mt-4">
              <CustomTextInput
                v-for="(field, index) in inputFields"
                :key="index"
                v-model="field.value"
                class="mb-2"
                :title="field.title"
                input-field-class="form-control w-20 text-center"
                :error-msg="field.errorMsg"
                :max-length="5"
                @input="resetAllError(inputFields)"
              />
              <FirstBootHelpNotif
                :show-notif="showFirstBootHelpNotif"
                :device-str="deviceStr"
                class="mt-3"
                @hide-notif="showFirstBootHelpNotif = false"
              />
            </div>
            <CustomButton text="login.menu_configure_this_device" custom-class="w-full btn btn-primary mt-4" @click="onLoginButtonClicked" />
            <a href= "https://app.emerit-services.com">
            <CustomButton text="Continuer sur Emerit Services" custom-class="w-full btn btn-success mt-4" 
              />
            </a>
          </div>

<!-- button class="bg-gray-550 text-white"
          <div v-if="!shouldPrintLogin"
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-3/5"
          >
          <div class="rounded p-5 mt-4">
            <Title title="Test titre" class="p-2 flex justify-center items-center "/>
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam euismod turpis id porttitor facilisis. Nullam vel arcu faucibus, cursus dui convallis, pulvinar dui. Pellentesque rhoncus ultricies felis, ac feugiat tellus iaculis pellentesque. Nunc sapien mauris, rhoncus ut risus eu, posuere elementum enim. Sed eget viverra risus. </p>
            <a href= "https://app.emerit-services.com">
              <CustomButton text="login.redirect_emerit_services" custom-class="w-full btn btn-primary mt-4"  />
            </a>
            <CustomButton text="login.stay_emerit_console" custom-class="w-full btn btn-secondary mt-4" @click="onEmeritConsoleButtonClicked" />
          </div>
          </div> -->
          <FirstPageRedirectEmeritServices
          :shouldPrintLogin = "shouldPrintLogin"
          @emeritConsoleButtonClicked="shouldPrintLogin= true"
          />

        </div>
      </div>
      <CustomToast
        ref="CustomToastInputError"
        toast-id="custom-toast-error"
        icon-component-name="XCircleIcon"
        icon-component-class="text-theme-6"
        text="login.menu_entered_numbers_dont_match_with_available_device"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { getCredValue } from "../../dao/cred-dao";
import { migrate } from "../../dao/migrations";
import { useStore } from "@/store";
import { checkFieldIsNotEmpty, resetAllError } from "../../utils/fieldManagement";
import { PATH_LOGIN, PATH_MODEL_LOGIN, PATH_PASSWORD, PATH_CREATE_PASSWORD } from "../../utils/const_path";
import {
  STORE_SET_PATH_DISCONNECTION,
  STORE_GET_IMG_FILE_FROM_S3,
  STORE_GET_DB_FILE_FROM_S3,
  STORE_UPDATE_LOGIN_STATUS,
  STORE_SAVE_DEVICE_INFO,
  STORE_S3_IMG_FILES_INFO,
  STORE_SAVE_SECRETS
} from "../../store/const_store";
import FirstBootHelpNotif from "./local-components/FirstBootHelpNotif.vue";
import FirstPageRedirectEmeritServices from "./local-components/FirstPageRedirectEmeritServices.vue";
import Title from "../../global-components/emerit/Texts/Title.vue"
import TextBold from "../../global-components/emerit/Texts/BoldText.vue"

import { fetchSecrets } from "../../utils/secretManager";

const IMEI_INDEX = 0;
const SN_INDEX = 1;

const I18_TITLE_ENTER_IMEI = "login.menu_enter_five_last_digits_of_imei_number";
const I18_TITLE_ENTER_SN = "login.menu_enter_five_last_digits_of_serial_number";

const I18_ERR_NO_DEVICE_SELECTED = "login.menu_please_select_the_device_model";
const I18_ERR_FIELD_IS_EMPTY = "common.menu_field_cannot_be_empty";

export default defineComponent({
  components: {
    FirstBootHelpNotif,
    FirstPageRedirectEmeritServices
  },

  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },

  data() {
    return {
      shouldPrintLogin :false,
      resetAllError: resetAllError,
      language: this.$i18n.locale,
      device: "",
      selectErrorMsg: "",
      inputFields: [
        {
          title: I18_TITLE_ENTER_IMEI,
          value: "",
          errorMsg: ""
        },
        {
          title: I18_TITLE_ENTER_SN,
          value: "",
          errorMsg: ""
        }
      ],
      imageSource: require(`@/assets/images/sigma-emerit-web.png`),
      deviceStr: useStore().state.emeritValues.deviceStr,
      showFirstBootHelpNotif: false
    };
  },

  watch: {
    language(newVal) {
      this.$i18n.locale = newVal;
    },

    device() {
      this.updateDeviceInfo();
      this.selectErrorMsg = "";
      this.imageSource = useStore().state.emeritValues.modelImageSource;
      this.deviceStr = useStore().state.emeritValues.deviceStr;
    }
  },

  created() {
    /*if (useStore().state.emeritValues.disconnectionPath === PATH_MODEL_LOGIN) {
      const deviceModelName = useStore().state.emeritValues.deviceInfo
        .deviceModelName;
      if (deviceModelName) this.device = deviceModelName;
    }*/
    fetchSecrets();
  },

  methods: {
    onLoginButtonClicked() {
      let error = 0;
      error += Number(!this.checkDevice());
      for (let i = 0; i < this.inputFields.length; ++i) error += Number(!checkFieldIsNotEmpty(this.inputFields, i, I18_ERR_FIELD_IS_EMPTY));
      if (!error) {
        this.updateDeviceInfo();
        this.$store.dispatch(STORE_GET_DB_FILE_FROM_S3, {
          self: this
        });
      }
    },

    onEmeritConsoleButtonClicked() {
     this.shouldPrintLogin = true;
    },

    checkDevice() {
      if (!this.device.length) this.selectErrorMsg = I18_ERR_NO_DEVICE_SELECTED;
      else this.selectErrorMsg = "";
      return !this.selectErrorMsg.length;
    },

    onDbDownloadCompleted() {
      migrate();
      this.$store.commit(STORE_SET_PATH_DISCONNECTION, PATH_LOGIN);
      for (let i = 0; i < STORE_S3_IMG_FILES_INFO.length; ++i) {
        this.$store.dispatch(STORE_GET_IMG_FILE_FROM_S3, {
          fileName: STORE_S3_IMG_FILES_INFO[i].fileName
        });
      }
      this.loginRouter();
    },

    loginRouter() {
      if (getCredValue() != useStore().state.emeritValues.secrets.initialCred) {
        this.$router.push(PATH_PASSWORD);
      } else {
        this.$router.push(PATH_CREATE_PASSWORD);
      }
    },

    onDbDownloadError() {
      this.$store.commit(STORE_UPDATE_LOGIN_STATUS, false);
      this.$refs.CustomToastInputError.trigger();
      this.showFirstBootHelpNotif = true;
    },

    updateDeviceInfo() {
      let deviceInfo = {
        deviceModelName: this.device,
        deviceIMEI: this.inputFields[IMEI_INDEX].value,
        deviceSerialNumber: this.inputFields[SN_INDEX].value
      };
      this.$store.commit(STORE_SAVE_DEVICE_INFO, deviceInfo);
    }
  }
});
</script>
