<template>
  <!-- BEGIN: SelectLanguageAndDevice component -->
  <div>
    <CustomSelect
      v-model="selectLanguage.language"
      title="login.menu_select_language"
      :options="selectLanguage.options"
      :values="selectLanguage.values"
    />
    <!--
    <CustomButton
      icon-component-name="HelpCircleIcon"
      text="login.menu_quick_start_guide"
      custom-class="mt-4 btn btn-warning"
      @click="openLink($t('login.menu_home_help'))"
    />
    -->
  </div>  
  <!-- END: SelectLanguageAndDevice component -->
</template>

<script>
import { defineComponent } from 'vue'


const DA_OPTION = "DA - Dänisch";
const DE_OPTION = "DE - Deutsch";
const DU_OPTION = "DU - Nederlandse";
const EN_OPTION = "EN - English";
const ES_OPTION = "ES - Español";
const FR_OPTION = "FR - Français";
const IT_OPTION = "IT - Italiano";

const DA_VALUE = "da";
const DE_VALUE = "de";
const DU_VALUE = "du";
const EN_VALUE = "en";
const ES_VALUE = "es";
const FR_VALUE = "fr";
const IT_VALUE = "it";


const EV_UPDATE_LANGUAGE = "update:language";
const EV_UPDATE_DEVICE = "update:device";

/**
 * -------------------------- DESCRIPTION ---------------------------
 * Two select fields for choosing website language and the device
 * to configure, and a help button. Used in all emerit-console
 * login screens.
 * 
 * ----------------------------- PROPS ------------------------------
 * @param {String} language
 *    Value of the language select field. Automatically sets when using 
 *    v-model on this component. As there are two select fields in this 
 *    component, you have to precise name of this props to v-model like 
 *    this: v-model:language="parentVariable". Will be initialized with 
 *    parent variable value.
 *
 * @param {String} errorMsg
 *    If empty, nothing appears. Otherwise, this string will appear 
 *    as an error in red to the user. Should be key for i18 locale.
 *    Empty by default, if no error management is needed do not fill it.
 *  
 * ------------------------- EVENTS EMITTED -------------------------
 * @emits update:language
 * @return {String}
 *    Emits the value of the language select field for v-model usage.
 * ------------------------------------------------------------------
 */
export default defineComponent({

  props: {
    language: {
      type: String,
      required: true,
    },
    errorMsg: {
      type: String,
      required: false,
    }
  },

  emits: [
    EV_UPDATE_LANGUAGE
  ],

  data() {
    return {
      selectLanguage: {
        language: this.$i18n.locale,
        options: [
          DA_OPTION,
          DE_OPTION,
          DU_OPTION,
          EN_OPTION,
          ES_OPTION,
          FR_OPTION,
          IT_OPTION,
        ],
        values: [
          DA_VALUE,
          DE_VALUE,
          DU_VALUE,
          EN_VALUE,
          ES_VALUE,
          FR_VALUE,
          IT_VALUE,
        ],
      },
    }
  },

  watch: {
    'selectLanguage.language': {
      handler: function updateLanguage(newVal) {
        this.$emit(EV_UPDATE_LANGUAGE, newVal);
      }
    },
  },

  created() {
    this.selectLanguage.language = this.language;
  },

  methods: {
    openLink(link) {
      window.open(link);
    }
  }
})
</script>
