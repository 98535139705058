<template>
  <!-- BEGIN: FirstBootHelpNotif local component    {{ $t('login.menu_redirection_page_avertissement') }} -->
    <div v-if="!shouldPrintLogin"
            class="my-auto flex-none mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-3/5"
          >

        <LanguageRedirectionPage class="mb-4"
        v-model:language="language"  />
        
          <div class="border rounded p-4 mt-2 bg-yellow-300 ">
          
           <!-- <BoxTitle title="Avertissement"  class="mb-2  flex justify-center items-center text-xl" /> -->

             <h2 class="mb-2 flex justify-center  font-bold items-center text-xl flex flex-row relative pt-3 p-3 bg-color-200 border-gray-200 dark:border-dark-5 rounded-t ">
             {{ $t('login.menu_redirection_page_avertissement') }}
            </h2>

             <p class="text-black font-sm mb-4">    
              {{ $t('login.menu_redirection_delivery') }}
               <a href="https://app.emerit-services.com " class="hover:text-blue-500 hover:underline"> https://app.emerit-services.com </a>
            </p>
  
            <p class="text-black font-sm mb-4">    
              {{ $t('login.menu_redirection_page_info') }} 
              <a href="https://app.emerit-services.com " class="hover:text-blue-500 hover:underline"> https://app.emerit-services.com </a>
            </p>
            <p class="text-black font-sm mb-4">
                   {{ $t('login.menu_redirection_help_redirect_mailto') }}
                    <a href="mailto:technique@magneta.fr " class="hover:text-blue-500 hover:underline"> technique@magneta.fr </a>
            </p>
          </div>
            <a href= "https://app.emerit-services.com">
              <CustomButton text="login.menu_redirect_emerit_services" custom-class="w-full btn btn-primary mt-4"  />
            </a>
            <CustomButton text="login.menu_redirection_page_stay_emerit_console" custom-class="w-full btn btn-secondary mt-4" @click="onEmeritConsoleButtonClicked" />
          </div>
 
  <!-- END: FirstBootHelpNotif local component -->
</template>

<script>
import { defineComponent } from 'vue'
import LanguageRedirectionPage from "./LanguageRedirectionPage.vue";

export default defineComponent({

  components: {
    LanguageRedirectionPage
  },

   data() {
    return {
      language: this.$i18n.locale,
    };
  },
  
  props: {
    shouldPrintLogin: {
      type: Boolean,
      required: true,
    }
  },

 methods: {
    onEmeritConsoleButtonClicked() {
      this.$emit('emeritConsoleButtonClicked');
    }
  },
    watch: {
    language(newVal) {
      this.$i18n.locale = newVal;
      }
    }
  
})
</script>

<!--<button class="btn-primary bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700">Primary</button>
  <button class="btn-secondary bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700">Secondary</button>
  <button class="btn-success bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700">Success</button>
  <button class="btn-danger bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700">Danger</button>
  <button class="btn-warning bg-yellow-500 text-black py-2 px-4 rounded hover:bg-yellow-600">Warning</button>
  <button class="btn-info bg-cyan-500 text-white py-2 px-4 rounded hover:bg-cyan-700">Info</button>
  <button class="btn-light bg-gray-100 text-black py-2 px-4 rounded hover:bg-gray-200">Light</button>
  <button class="btn-dark bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-900">Dark</button>
</div> -->

